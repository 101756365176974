import React from "react";
import "../index.css";
import { motion, useInView } from "framer-motion";

//assets
import ArcexOne from "../assets/arcexOne.jpg";
import ArcexTwo from "../assets/arcexTwo.png";
import GreenBlur2 from "../assets/greenBlur2.svg";

export default function Features2() {

  const liquidationRef = React.useRef(null);
  const isLiquidationInView = useInView(liquidationRef, { amount: "some" });

  const slideRef = React.useRef(null);
  const isSlideInView = useInView(slideRef, { amount: "some" });

  const investRef = React.useRef(null);
  const isInvestInView = useInView(investRef, { amount: "some" });

  const arcexTwoRef = React.useRef(null);
  const isArcexTwoInView = useInView(arcexTwoRef, { amount: "some" });

  const arcexOneRef = React.useRef(null);
  const isArcexOneInView = useInView(arcexOneRef, { amount: "some" });

  return (
    <div className="flex flex-col items-center justify-center max-h-[100vh] my-[80px] px-4 sm:px-0 gap-2 sm:gap-4 md:gap-6 w-full mx-auto bg-no-repeat bg-white bg-center bg-[length:200%_200%] sm:bg-[length:140%_140%] md:bg-[length:120%_120%] lg:bg-[length:100%_100%] xl:bg-[length:90%_90%] overflow-hidden"
      style={{ backgroundImage: `url(${GreenBlur2})` }}
    >
      <h1 className="font-medium text-2xl sm:text-4xl text-center mb-6">
        <span className='whitespace-nowrap mr-2'>No experience?</span>
        <span className='whitespace-nowrap'>No problem!</span>
      </h1>

      <motion.div 
        className="flex flex-col justify-center gap-2 items-center bg-white/100 border border-theme-grayText sm:w-4/5 md:w-2/3 rounded-lg sm:rounded-xl text-center shadow-lg max-w-[700px] min-h-[160px] py-3 lg:py-6 px-6"
        ref={liquidationRef}
        animate={{ opacity: isLiquidationInView ? 1 : 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <h1 className="font-bold text-[1.2rem] sm:text-[1.3rem] md:text-[1.6rem]">
          The Future of CEX & DEX
        </h1>
        <h2 className='font-medium text-[1rem] md:text-[1.2rem] lg:text-[1.4rem]'>
          Trade with the security of <b>Arcex</b> or take full control as your own custodian.
        </h2>
      </motion.div>

      <div className="flex w-full sm:w-4/5 md:w-2/3 max-w-[900px] min-h-[180px] gap-2 sm:gap-4 md:gap-6">
        <motion.div
          className="flex w-2/3 flex-col bg-white/100 justify-center gap-2 border border-theme-grayText rounded-lg sm:rounded-xl px-6 py-4 shadow-lg"
          ref={slideRef}
          initial={{ x: -100, opacity: 0 }}
          animate={{ 
            x: isSlideInView ? 0 : -100, 
            opacity: isSlideInView ? 1 : 0 
          }}
          transition={{ duration: 0.5, delay: 0.7, ease: "easeOut" }}
        >
          <h1 className="font-bold text-[1.2rem] sm:text-[1.3rem] md:text-[1.6rem]">Send / Wager with <b>Anyone</b></h1>
          <h2 className="font-medium text-[1rem] md:text-[1.2rem] lg:text-[1.4rem]">Securely send your assets to others in seconds with ease, or propose wagers to your friends.</h2>
        </motion.div>

        <motion.div 
          ref={arcexTwoRef}
          style={{ backgroundImage: `url(${ArcexTwo})` }}
          className="flex items-center border border-theme-grayText z-10 shadow-lg rounded-lg sm:rounded-xl bg-[#e5f7ed] w-1/3 bg-cover bg-no-repeat bg-center overflow-hidden"
          initial={{ x: 100, opacity: 0 }}
          animate={{ 
            x: isArcexTwoInView ? 0 : 100, 
            opacity: isArcexTwoInView ? 1 : 0 
          }}
          transition={{ duration: 0.5, delay: 0.9, ease: "easeOut" }}
        >
        </motion.div>
      </div>

      <div className="flex w-full sm:w-4/5 md:w-2/3 max-w-[700px] gap-2 sm:gap-4 md:gap-6">
        <motion.div
          className="flex w-2/3 flex-col bg-white/100 justify-center gap-2 border border-theme-grayText rounded-lg sm:rounded-xl px-6 py-4 shadow-lg"
          ref={investRef}
          initial={{ x: -100, opacity: 0 }}
          animate={{ 
            x: isInvestInView ? 0 : -100, 
            opacity: isInvestInView ? 1 : 0 
          }}
          transition={{ duration: 0.5, delay: 1, ease: "easeOut" }}
        >
          <h1 className="font-bold text-[1.2rem] sm:text-[1.3rem] md:text-[1.6rem]">Simple Investing, <span className='whitespace-nowrap'>Endless Opportunities</span></h1>
          <h2 className="font-medium text-[1rem] md:text-[1.2rem] lg:text-[1.4rem]">Explore a diverse range of assets across global markets.</h2>
        </motion.div>
        <motion.div 
          ref={arcexOneRef}
          className="flex flex-col border border-theme-grayText shadow-lg rounded-lg sm:rounded-xl w-1/2 bg-cover bg-center overflow-hidden"
          style={{ backgroundImage: `url(${ArcexOne})` }}
          initial={{ x: 100, opacity: 0 }}
          animate={{ 
            x: isArcexOneInView ? 0 : 100, 
            opacity: isArcexOneInView ? 1 : 0 
          }}
          transition={{ duration: 0.5, delay: 1.1, ease: "easeOut" }}
        >
        </motion.div>
      </div>

      <div className="h-[40px]"></div>
    </div>
  );
}
