import React, {useState, useEffect} from "react";
import { motion } from 'framer-motion';

//assets
import LogoNew from '../assets/logoDarkGreen.svg';
import Bell from '../assets/bell.svg';

const handleScrollToBottom = (event) => {
  event.preventDefault()
  window.scrollTo({
      top: 9000,
      behavior: 'smooth',
  });
};

const handleScrollToTop = (event) => {
  event.preventDefault()
  window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
};

export default function Navbar() {
  const [updatesModal, setUpdatesModal] = useState(false);
  const [formEmail, setFormEmail] = useState({email: ""});
  const [isNearTop, setIsNearTop] = useState(false);

  // Add mouse position tracking
  useEffect(() => {
    const handleMouseMove = (event) => {
      setIsNearTop(event.clientY <= 300);
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const handleUpdatesModal = () => {
    setUpdatesModal(!updatesModal)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormEmail({ ...formEmail, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("data", formEmail)

    try {
      const response = await fetch("https://landing-be-production.up.railway.app/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formEmail),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      console.log("Signed up successfully");
    } catch (error) {
      console.error("Error signing up for updates:", error);
    }
    setUpdatesModal(false)
  };

  const modalComponent = () => (
    <div className="fixed inset-0 flex items-center justify-center cursor-pointer bg-black bg-opacity-60 z-50" onClick={handleUpdatesModal}>
      <div className="flex flex-col items-center grow gap-2 max-w-[500px] cursor-default w-full sm:w-auto bg-white rounded-lg sm:rounded-xl shadow-md py-6 px-8" onClick={(e) => e.stopPropagation()}>
        <h1>Join the Beta</h1>
        <h2 className='text-center'>Enter your email to stay updated on Arcex and be notified when testing builds become available</h2>
        <form className="flex flex-col gap-4 grow w-full max-w-[400px]" onSubmit={handleSubmit}>
          <input
            type="email"
            id="email"
            name="email"
            className=" w-full py-2 px-3 rounded-md border border-theme-grayText shadow-sm focus:border-theme-border focus:ring-theme-border focus:outline-none"
            placeholder="your@email.com"
            onChange={handleChange}
            required
            autoComplete="on"
          />

          <button type="submit" className="inline-flex justify-center py-2 border border-theme-grayText shadow-sm rounded-md text-theme-text bg-theme-green hover:bg-white transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-grayText">
            <h2>Get Updates</h2>
          </button>
        </form>
      </div>
    </div>
  )

  return (
    <>
      {/* Fixed position floating nav */}
      <motion.div 
        className='relative invisible xl:visible'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 4, ease: "easeInOut" }}
      >
        <div className={`group ${isNearTop ? 'bg-white/95' : 'bg-white/30'} transition-all duration-300 backdrop-blur-[2px] rounded-lg shadow-md fixed top-6 left-[5%] flex justify-center p-2 cursor-pointer`} onClick={handleScrollToTop}>
          <img src={LogoNew} alt='Arcex' className={`w-[80px] md:w-[120px] transition-opacity drop-shadow-[0px_2px_4px_rgba(0,0,0,0.15)] ${isNearTop ? 'opacity-50' : 'opacity-40'} hover:opacity-100`} />
        </div>

        <div className={`${isNearTop ? 'bg-white/95' : 'bg-white/40'} transition-all duration-300 backdrop-blur-[2px] rounded-lg shadow-md fixed top-6 right-[5%] flex flex-col items-center justify-center overflow-hidden`}>
          <div className={`px-10 py-2 w-full justify-center cursor-pointer ${isNearTop ? 'opacity-80' : 'opacity-60'} hover:opacity-100 transition-all duration-200`} onClick={handleUpdatesModal}>
            <h3 className="text-theme-greenText text-center">Join the Beta</h3>
          </div>
          <div className='w-[90%] h-[1px] bg-theme-grayText/20 mx-auto'></div> 
          <div className='w-[90%] h-[1px] bg-theme-grayText/5 mx-auto'></div>          
          <div className={`px-10 py-2 w-full justify-center cursor-pointer ${isNearTop ? 'opacity-60' : 'opacity-40'} hover:opacity-100 transition-all duration-200`} onClick={() => window.open(`https://investments.arcex.app/`)}>
            <h3 className='text-center'>Investors</h3>
          </div>
          <div className='w-[90%] h-[1px] bg-theme-grayText/20 mx-auto'></div> 
          <div className='w-[90%] h-[1px] bg-theme-grayText/5 mx-auto'></div>
          <div className={`px-10 py-2 w-full justify-center cursor-pointer ${isNearTop ? 'opacity-60' : 'opacity-40'} hover:opacity-100 transition-all duration-200`} onClick={handleScrollToBottom}>
            <h3 className='text-center'>Contact</h3>
          </div>
        </div>
      </motion.div>
      
      {/* Normal navbar for small screens */}
      <motion.div 
        className="xl:invisible flex justify-between items-center pt-4 px-4 sm:px-0 w-full sm:w-[90%] mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 4, ease: "easeInOut" }}
      >
        <img src={LogoNew} alt='Arcex' className='w-[80px] sm:mr-[65px] md:w-[120px] md:mr-[40px] h-[auto]' />

        <div className="flex items-center justify-center gap-8 sm:justify-around grow max-w-[210px] md:max-w-[260px]">
          <h3 className="cursor-pointer" onClick={() => window.open(`https://investments.arcex.app/`)}>Investors</h3>
          <h3 className="cursor-pointer" onClick={handleScrollToBottom}>Contact</h3>
        </div>

        <div onClick={handleUpdatesModal} className="flex items-center justify-center gap-2 bg-theme-green border border-theme-grayText cursor-pointer px-2 shadow sm:px-0 min-w-[80px] sm:w-[145px] md:w-[160px] py-2 rounded-lg sm:rounded-xl">
          <img src={Bell} alt='Notify me' className='animate-bell size-[20px]' />
          <h3 className='hidden sm:inline-block'>Join the Beta</h3>
        </div>
      </motion.div>

      {updatesModal && modalComponent()}
    </>
  );
}