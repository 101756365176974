import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';

//components
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features1 from './components/Features1'
import Features2 from './components/Features2';
import Contact from './components/Contact';
import Security from './components/Security';
import ChangeLog from './components/ChangeLog';
import LoadingScreen from './components/LoadingScreen';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleHeroLoaded = () => {
      setIsLoading(false);
    };

    window.addEventListener('heroLoaded', handleHeroLoaded);
    return () => window.removeEventListener('heroLoaded', handleHeroLoaded);
  }, []);

  return (
    <Router>
      <div>
        {isLoading && <LoadingScreen />}
        <div className='relative z-20 h-[40px]'>
          <Navbar />
        </div>
        <Routes>
          <Route path="/" element={
            <div className='relative z-10'>
              <Hero setAppLoading={setIsLoading} />
              <Features1 />
              <Features2 />
              <Contact />
            </div>
          }/>
          <Route path="/security" element={<Security />} />
          <Route path="/changelog" element={<ChangeLog />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;