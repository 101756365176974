import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

//Assets
import greenBlur from '../assets/greenBlur.svg';
import LandingFriendsList from '../assets/landingFriendsList.png';
import LandingTradeAdvanced from '../assets/landingTradeAdvanced.png';
import LandingPips from '../assets/landingPips.png';
import LandingTradeBasic from '../assets/landingTradeBasic.png';
import LandingFriend from '../assets/landingFriend.png';
import LandingWatchlist from '../assets/landingWatchlist.png';
import LandingBoosts from '../assets/landingBoosts.png';
import ArcexLogo from '../assets/logoDarkGreen.svg';

export default function Hero() {
  // Add loading state
  const [isLoading, setIsLoading] = useState(true);
  const [assetsLoaded, setAssetsLoaded] = useState(false);
  
  // Add this state to detect if the device is mobile
  const [isMobile, setIsMobile] = useState(false);
  
  // Add Safari detection state
  const [isSafari, setIsSafari] = useState(false);
  
  // Check for mobile devices immediately
  useEffect(() => {
    try {
      // Check if browser is Safari
      const checkIfSafari = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        return userAgent.includes('safari') && !userAgent.includes('chrome');
      };

      const checkIfMobile = () => {
        const isMobileDevice = window.innerWidth < 768 || 
                              'ontouchstart' in window || 
                              navigator.maxTouchPoints > 0 ||
                              /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsMobile(isMobileDevice);
        setIsSafari(checkIfSafari());
      };
      
      checkIfMobile();
      window.addEventListener('resize', checkIfMobile);
      
      return () => window.removeEventListener('resize', checkIfMobile);
    } catch (error) {
      console.error("Error in device detection effect:", error);
      setIsMobile(true);
      setIsSafari(false);
    }
  }, []);
  
  // Load all assets first
  useEffect(() => {
    try {
      const imageUrls = [
        greenBlur, LandingFriendsList, LandingTradeAdvanced, LandingPips, 
        LandingTradeBasic, LandingFriend, LandingWatchlist, LandingBoosts, 
        ArcexLogo
      ];
      
      let loadedCount = 0;
      const totalImages = imageUrls.length;
      
      // Preload all images
      imageUrls.forEach(url => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
          loadedCount++;
          if (loadedCount === totalImages) {
            setAssetsLoaded(true);
          }
        };
        img.onerror = () => {
          loadedCount++;
          console.error(`Failed to load image: ${url}`);
          if (loadedCount === totalImages) {
            setAssetsLoaded(true);
          }
        };
      });
      
      // Safety timeout - if images don't load within 5 seconds, proceed anyway
      const safetyTimer = setTimeout(() => {
        setAssetsLoaded(true);
      }, 5000);
      
      return () => clearTimeout(safetyTimer);
    } catch (error) {
      console.error("Error in asset loading:", error);
      setAssetsLoaded(true); // Proceed despite error
    }
  }, []);
  
  // Set loading to false after assets are loaded and a short delay
  useEffect(() => {
    if (assetsLoaded) {
      const timer = setTimeout(() => {
        setIsLoading(false);
        window.dispatchEvent(new Event('heroLoaded'));
      }, 500); // Short delay to ensure everything is ready
      
      return () => clearTimeout(timer);
    }
  }, [assetsLoaded]);


  // Add mouse position state with a flag for when cursor is outside window
  const [mousePosition, setMousePosition] = React.useState({ 
    x: 0, 
    y: 0,
    isInWindow: false 
  });
  
  // Track mouse movement, enter and leave - only on desktop
  React.useEffect(() => {
    // Skip mouse tracking on mobile devices
    if (isMobile) return;
    
    try {
      const handleMouseMove = (e) => {
        setMousePosition({
          x: e.clientX,
          y: e.clientY,
          isInWindow: true
        });
      };
      
      const handleMouseLeave = () => {
        setMousePosition({
          x: 0,
          y: 0,
          isInWindow: false
        });
      };
      
      const handleMouseEnter = () => {
        setMousePosition(prev => ({
          ...prev,
          isInWindow: true
        }));
      };
      
      window.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseleave', handleMouseLeave);
      document.addEventListener('mouseenter', handleMouseEnter);
      
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseleave', handleMouseLeave);
        document.removeEventListener('mouseenter', handleMouseEnter);
      };
    } catch (error) {
      console.error("Error in mouse tracking effect:", error);
    }
  }, [isMobile]);

  const landingImgStyle = 'w-[280px] z-20 opacity-0'
  const landingImgStyleWatch = 'w-[289px] z-20 opacity-0' // width 1.03125x
  const landingColumnStyle = 'flex flex-col justify-center items-center gap-[5px] sm:gap-[10px]'

  // Add this state to your component
  const [parallaxActive, setParallaxActive] = useState(false);

  // Add this effect to enable parallax after delay - only on desktop
  useEffect(() => {
    if (isMobile || isLoading) return;
    
    const timer = setTimeout(() => {
      setParallaxActive(true);
    }, 3500);
    
    return () => clearTimeout(timer);
  }, [isLoading, isMobile]);

  const calculateParallax = (index, imgRef) => {
    // On mobile or Safari, always return a neutral transform
    if (isMobile || isSafari) {
      return { transform: 'translate(0px, 0px)' };
    }
    
    try {
      // Return no parallax effect if conditions aren't met
      if (!imgRef || !imgRef.current || !mousePosition.isInWindow || !parallaxActive) {
        return { 
          transform: 'translate(0px, 0px)',
          transition: 'transform 6s ease-out',
          willChange: 'transform',
          backfaceVisibility: 'hidden',
          WebkitBackfaceVisibility: 'hidden'
        };
      }
      
      // Get image position and dimensions
      const rect = imgRef.current.getBoundingClientRect();
      const imgCenterX = rect.left + rect.width / 2;
      const imgCenterY = rect.top + rect.height / 2;
      const imgTop = rect.top;
      
      // Calculate distance from mouse to image center
      const distanceX = mousePosition.x - imgCenterX;
      const distanceY = mousePosition.y - imgCenterY;
      
      // Calculate distance (Euclidean)
      const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);
      
      // Max distance to consider for effect (in pixels)
      const maxDistance = 500;
      
      // Calculate intensity based on proximity (closer = stronger effect)
      const intensity = Math.max(0, 1 - distance / maxDistance);
      
      // Base movement factor (different for each image to create depth)
      const baseMoveFactor = 10 + (index % 3) * 2;
      
      // Apply proximity factor to movement - increase horizontal multiplier
      const horizontalMultiplier = 6; // Increase this value for more horizontal movement
      const verticalMultiplier = 3;
      
      // Calculate movement direction (now moving TOWARD the cursor)
      const moveX = distanceX !== 0 ? distanceX / Math.abs(distanceX) * baseMoveFactor * intensity * horizontalMultiplier : 0;
      let moveY = distanceY !== 0 ? distanceY / Math.abs(distanceY) * baseMoveFactor * intensity * verticalMultiplier : 0;
      
      // Only apply top restriction if user is scrolled near the top of the page
      // and only for the boosts image (index 2)
      if (index === 2 && window.scrollY < 80 && imgTop + moveY < 80) {
        moveY = Math.max(80 - imgTop, moveY);
      }
      
      return { // When cursor is near and parallax is triggered, return this
        transform: `translate3d(${moveX}px, ${moveY}px, 0)`,
        transition: 'transform 2.4s ease-out',
        willChange: 'transform',
        backfaceVisibility: 'hidden',
        WebkitBackfaceVisibility: 'hidden'
      };
    } catch (error) {
      console.error("Error in parallax calculation:", error);
      return { transform: 'translate(0px, 0px)' };
    }
  };

  // Create refs for all images
  const imgRefs = {
    friendsList: React.useRef(null),
    tradeAdvanced: React.useRef(null),
    boosts: React.useRef(null),
    watchlist: React.useRef(null),
    pips: React.useRef(null),
    tradeBasic: React.useRef(null),
    friend: React.useRef(null)
  };

  // Loading screen
  if (isLoading) {
    return null;
  }

  return (
      <div 
        className="flex flex-row pt-2 px-[20px] sm:px-0 gap-[5px] sm:gap-[10px] lg:gap-[50px] mx-auto mb-20 justify-center items-center w-full sm:w-[90%] h-[80vh] sm:h-[90vh] bg-no-repeat bg-center bg-[length:90%_90%]"
        style={{ backgroundImage: `url(${greenBlur})` }}
      >
        <div className={`${landingColumnStyle}`}>
          <motion.div style={calculateParallax(0, imgRefs.friendsList)}>
            <motion.img 
              ref={imgRefs.friendsList}
              src={LandingFriendsList} 
              className={`${landingImgStyle}`}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: [0.95, 1.05, 1]}}
              transition={{ 
                opacity: { duration: 0.5, delay: 2.2, ease: "easeOut" },
                scale: { duration: 0.5, delay: 2.2, times: [0, 0.4, 1], ease: "backOut"}
              }}
            />
          </motion.div>
          <motion.div style={calculateParallax(1, imgRefs.tradeAdvanced)}>
            <motion.img 
              ref={imgRefs.tradeAdvanced}
              src={LandingTradeAdvanced} 
              className={`${landingImgStyle}`}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: [0.95, 1.05, 1]}}
              transition={{ 
                opacity: { duration: 0.3, delay: 2.6, ease: "easeOut" },
                scale: { duration: 0.3, delay: 2.6, times: [0, 0.4, 1], ease: "backOut"}
              }}
            />
          </motion.div>
        </div>
        
        <div className={`${landingColumnStyle}`}>
          <motion.div style={{...calculateParallax(2, imgRefs.boosts), zIndex: 20}}>
            <motion.img 
              ref={imgRefs.boosts}
              src={LandingBoosts} 
              className={`${landingImgStyle}`}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: [0.95, 1.05, 1]}}
              transition={{ 
                opacity: { duration: 0.3, delay: 2.8, ease: "easeOut" },
                scale: { duration: 0.3, delay: 2.8, times: [0, 0.4, 1], ease: "backOut"}
              }}
            />
          </motion.div>

          {/* Center logo area */}
          <div className='flex flex-col justify-center items-center w-full my-[20px] sm:my-[40px] 2xl:my-[40px] relative'>
            {/* Blurry circle */}
            <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 size-[180px] sm:size-[240px] md:size-[300px] rounded-full bg-white blur-2xl sm:opacity-80'></div>
  
            {/* Circle content*/}
            <motion.div 
              className='z-20 flex flex-col justify-center w-full items-center gap-2'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2, delay: 0.8, ease: "easeInOut" }}
            >
              <img 
                src={ArcexLogo}
                alt='Arcex'
                className='z-20 w-full max-w-[240px] drop-shadow-[0px_2px_4px_rgba(0,0,0,0.15)]'
              />
              <h2 className='z-20 text-center text-sm sm:text-base drop-shadow-[0px_1px_3px_rgba(0,0,0,0.15)]'>
                Unifying the CEX & DEX experience
              </h2>
            </motion.div>
            
          </div>

          <motion.div style={{...calculateParallax(4, imgRefs.watchlist), zIndex: 20}}>
            <motion.img 
              ref={imgRefs.watchlist}
              src={LandingWatchlist} 
              className={`${landingImgStyleWatch}`}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: [0.95, 1.05, 1]}}
              transition={{ 
                opacity: { duration: 0.4, delay: 2.4, ease: "easeOut" },
                scale: { duration: 0.4, delay: 2.4, times: [0, 0.4, 1], ease: "backOut"}
              }}
            />
          </motion.div>
        </div>

        <div className={`${landingColumnStyle}`}>
          <motion.div style={calculateParallax(5, imgRefs.pips)}>
            <motion.img 
              ref={imgRefs.pips}
              src={LandingPips} 
              className={`${landingImgStyle}`}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: [0.95, 1.05, 1]}}
              transition={{ 
                opacity: { duration: 0.4, delay: 2.5, ease: "easeOut" },
                scale: { duration: 0.4, delay: 2.5, times: [0, 0.4, 1], ease: "backOut"}
              }}
            />
          </motion.div>
          <motion.div style={calculateParallax(6, imgRefs.tradeBasic)}>
            <motion.img 
              ref={imgRefs.tradeBasic}
              src={LandingTradeBasic} 
              className={`${landingImgStyle} opacity-0`}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: [0.95, 1.05, 1]}}
              transition={{ 
                opacity: { duration: 0.5, delay: 2, ease: "easeOut" },
                scale: { duration: 0.5, delay: 2, times: [0, 0.4, 1], ease: "backOut"}
              }}
            />
          </motion.div>
          <motion.div style={calculateParallax(7, imgRefs.friend)}>
            <motion.img 
              ref={imgRefs.friend}
              src={LandingFriend} 
              className={`${landingImgStyle}`}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: [0.95, 1.05, 1]}}
              transition={{ 
                opacity: { duration: 0.3, delay: 2.7, ease: "easeOut" },
                scale: { duration: 0.3, delay: 2.7, times: [0, 0.4, 1], ease: "backOut"}
              }}
            />
          </motion.div>
        </div>
      </div>
  );
}
