import React from 'react';
import ArcexA from '../assets/arcexA.svg';

export default function LoadingScreen() {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-[100] bg-white">
      <div className="flex flex-col items-center opacity-80">
        <img 
          src={ArcexA}
          alt="Arcex"
          className="w-[40px]"
        />
        <h3 className="mt-4">Loading...</h3>
      </div>
    </div>
  );
} 