import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

//icons
import Rewards from '../assets/rewards.svg';
import Social from '../assets/social.svg';
import Portfolio from '../assets/portfolio.svg';

//Assets
import mockYellow from "../assets/mockYellow.png";
import mockRed from "../assets/mockRed.png";
import mockGreen from "../assets/mockGreen.png";

export default function Features1() {

  const [currentSlide, setCurrentSlide] = useState('red')

  return (
    <div className="bg-theme-white min-h-[80vh] sm:px-[5%] flex flex-col justify-center">
      <div className="bg-gradient-to-bl from-[#f9ffbf] to-[#c1ffd6] px-4 py-[60px] sm:py-10 md:py-6 sm:px-[5%] flex flex-col justify-center sm:flex-row-reverse sm:rounded-[20px] sm:shadow-xl">

      <div className="mx-auto sm:mx-0 w-full sm:w-[33%] flex flex-col min-h-[90px] sm:min-h-[60px] justify-center items-center">
        <AnimatePresence mode="wait">
          <motion.h1
            key={currentSlide} // Add key to trigger animation on change
            className="font-medium text-xl md:text-2xl lg:text-4xl text-center"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ 
              type: "spring", 
              stiffness: 100, 
              damping: 20,
              duration: 0.1
            }}
          >
            {currentSlide === 'yellow' ? 'Activate promotional boosts to enhance your trading experience, earning Pips the whole way' : 
              currentSlide ==='red' ? 'All of your assets, all in one place' : 
              `Add your friends, send/request crypto and cash, propose wagers` }
          </motion.h1>
        </AnimatePresence>
      </div>

      <div className='flex items-center w-1/2 mx-auto sm:my-auto max-h-[680px] p-4 justify-center relative'>
        {/* Hidden reference image to set the height */}
        <img 
          src={mockYellow} 
          alt="" 
          className="w-full max-h-[680px] block invisible" 
          aria-hidden="true"
        />
        
        {/* Actual images for display */}
        <AnimatePresence mode="sync">
          {['yellow', 'red', 'green'].map(slide => (
            currentSlide === slide && (
              <motion.img
                key={slide}
                src={slide === 'yellow' ? mockYellow : slide === 'red' ? mockRed : mockGreen}
                alt="Arcex mockup"
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 drop-shadow-md max-h-[680px] p-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ 
                  duration: 0.5,
                  ease: "easeInOut"
                }}
              />
            )
          ))}
        </AnimatePresence>
      </div>

      <div className="w-full sm:w-[33%] max-w-[400px] sm:max-w-[auto] mx-auto flex flex-row sm:flex-col flex-wrap sm:flex-nowrap items-center space-x-2 sm:space-x-0 justify-center">
        {[
          [Rewards, 'Earn Rewards', () => setCurrentSlide('yellow'), 'yellow'],
          [Portfolio, 'Build a Portfolio', () => setCurrentSlide('red'), 'red'],
          [Social, 'Connect with Friends', () => setCurrentSlide('green'), 'green'],
        ].map(([image, label, onClick, activeSlide]) => (
          <div 
            key={label} 
            onClick={onClick} 
            className={`flex items-center justify-center sm:justify-start sm:w-full max-w-[210px] sm:max-w-[360px] my-2 border rounded-lg px-2 sm:rounded-xl shadow-md cursor-pointer hover:shadow-lg transition-all h-[40px] sm:h-[60px] md:h-[70px] ${activeSlide === currentSlide ? 'bg-theme-yellow border-theme-border' : 'bg-theme-white border-theme-grayText'}`}
          >
            <img src={image} className="size-[30px] md:size-[34px] lg:size-[40px] mr-2" alt='icon' />
            <h2 className='font-[500] text-[1rem] md:text-[1.2rem] leading-[1rem] sm:leading-[1.6rem] md:leading-[2rem]'>{label}</h2>
          </div>
        ))}
      </div>

      </div>
    </div>
  );
}
